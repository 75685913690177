/* 	ABOUT: 
	This plugin finds multiple wait times and displays the shortest one to the user. 
	The wait time has very little markup as it comes in. Here is the example markup that we get:
	
	<p class="ERWTRow">
		<span>Hospital Name (in an anchor tag...hopefully)</span>
		<span class="Time">15 min<br/>as of 4:30 PM on 3/14/2015</span>
	</p>
	
	This plugin takes the above markup (or any of the other example markup scenarios listed below) and provides the 
	following structure and classes for easy styling in CSS:
	
	<div id="WaitTimeWrapper">
		<div class="Number">15</div>
		<div class="Minutes">MINUTES</div>
		<div class="WaitTimeInfo">
			<div class="Hospital"><a href="<link to hospital>">Hospital Name</a></div>
			<div class="TimeStamp">Updated at 4:30 pm on 3/14/2015</div>
		</div>
	</div>

	 --- Every possible scenario for wait time output ---
	<p class="ERWTRow"><span>Hospital Name</span><span class="Time">No Wait<br/>as of 12:00 PM on 1/23/2015</span></p>
	<p class="ERWTRow"><span>Hospital Name</span><span class="Time">15 min<br/>as of 12:00 PM on 1/23/2015</span></p>
	//  and every 15 minute increment in between  
	<p class="ERWTRow"><span>Hospital Name</span><span class="Time">3 hr 0 min<br/>as of 12:00 PM on 1/23/2015</span></p>
	<p class="ERWTRow"><span>Hospital Name</span><span class="Time">*Any Custom Message*<br/>as of 12:00 PM on 1/23/2015</span></p>
	
	USAGE: 
	$('#PanelWaitTime').shortestWaitTime({
		waitTimeContainer : this,		- MANDATORY	- The markup where we want to display the shortest wait time	
		errorText : 'No results.',		- OPTIONAL - What to display to the user if there is no shortest time to display. 'No current information available.' is the default
		waitTimeText : 'Current as of'	- OPTIONAL - Customizable text. 											
													 //The original markup gives us: <time> "as of" <timestamp>.  
													 //The default is: <time> "Updated at" <timestamp>
	});
	
	*This is currently being used on the homepage of GenesisHealth: http://www.genesishealth.com/
*/

(function($) {
	$.fn.shortestWaitTime = function(options) {
		
		$.fn.shortestWaitTime.defaults = {
			errorText : 'No current information available.',
			waitTimeText : 'Updated at'						
		};
		var options = $.extend({}, $.fn.shortestWaitTime.defaults, options);
		
		var hospital = '';
		var waitTime = '';
		var time = '';
		var dateTimeArray = [];
		var timeArray = [];
		var waitTimeArray = [];
	
		$(options.waitTimeContainer).find('.ERWTRow').each(function (i, el) {
			$(el).hide(); //we initially hide all wait time rows and show the one with the lowest time later
			hospital = $(el).find('span:first-child').html();     //hospital name
			waitTime = $(el).find('span.Time').html();            //the contents of the Time class
			dateTimeArray = waitTime.toLowerCase().split('<br>'); //two array entries separated by the <br/> tag
			timeArray = dateTimeArray[0].split(' ');              //array entries for the time plus mins or time plus hours and mins
			timeStamp = dateTimeArray[1].toLowerCase().replace('as of', options.waitTimeText); //change the wording
			time = timeArray[0];
	
			if (dateTimeArray[0].toLowerCase() == "no wait") {//'No wait' was displayed as a wait time. Change the time to 0.
				time = 0;
			} else if (!isNumber(time)) {//a custon wait time was displayed. No time information is available. If every wait time has this, we show the error message
				time = Number.MAX_VALUE;
			}
	
			if (dateTimeArray[0].toLowerCase().indexOf('hr') > -1) {//look to see if hours are involved
				time = parseInt(time * 60); //multiply the hours * 60 to get the total minutes
				time += parseInt(timeArray[2]); //add the hours and minutes together
			}
	
			//********************************* FOR TESTING ONLY ********************************
			//time = 0; //test if every entry has "No Wait" applied
			//time = Number.MAX_VALUE; //test if every entry is a custom message (no wait time)
			//console.log($(el).html()); //see what the original markup was in the console
			//***********************************************************************************

			waitTimeArray.push(time);
			//change the markup of each wait time row. (they are all still hidden)
			$(el).html("<div id='WaitTimeWrapper'><div class='Number'>" + time + "</div><div class='Minutes'>MINUTES</div><div class='WaitTimeInfo'><div class='Hospital'>" + hospital + "</div><div class='TimeStamp'>" + timeStamp + "</div></div></div>");
		});
	
		var index = -1;
		var smallest = Number.MAX_VALUE;
		//find the shortest wait time and store the index to that time in the array
		for (var i = 0; i < waitTimeArray.length; i++) {
			if (waitTimeArray[i] < smallest) {
				smallest = waitTimeArray[i];
				index = i;
			}
		}

		if (index > -1) { //if a real wait time exists
			$('.ERWTRow').eq(index).show(); //show the updated row that is the shortest wait time
		} else { //otherwise show the error message
			$(options.waitTimeContainer).append("<div id='WaitTimeWrapper'>"+ options.errorText +"</div>");
		}
	
		function isNumber(n) {
			return !isNaN(n - 0) && n !== null && n !== "" && n !== false;
		}
	};
}(jQuery));